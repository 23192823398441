import React, { useState } from "react";

export default function Contact() {
  const [formData, setFormData] = useState({
    email: "",
    subject: "",
    message: "",
  });

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData({ ...formData, [id]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const mailtoLink = `mailto:test@ttcrehlingen.de?subject=${encodeURIComponent(
      formData.subject
    )}&body=${encodeURIComponent(formData.message)}`;
    window.location.href = mailtoLink;
  };

  return (
    <section className="bg-white dark:bg-gray-900">
      <div className="py-8 sm:mx-auto">
        <div className="mx-auto max-w-screen-sm text-center lg:mb-16 mb-8 absolute top-80 sm:top-72 md:top-72 lg:top-72 xl:top-72 left-0 right-0">
          <h2 className="mb-4 text-2xl sm:text-3xl lg:text-4xl tracking-tight font-extrabold text-white dark:text-white">
            Fragen?
          </h2>
          <p className="font-light text-slate-100 text-sm sm:text-lg lg:text-xl text-white dark:text-gray-400">
            Wir freuen uns über Ihre Nachricht und helfen gerne weiter.
          </p>
        </div>
        <div className="bg-white w-11/12 sm:w-3/4 lg:w-1/2 mx-auto pt-4 flex justify-center">
          <form onSubmit={handleSubmit} className="space-y-8 w-full">
            <div>
              <label
                for="email"
                className="flex sm:block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Deine Email
              </label>
              <input
                type="email"
                id="email"
                className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light"
                placeholder="Email Adresse"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </div>
            <div>
              <label
                for="subject"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Betreff
              </label>
              <input
                type="text"
                id="subject"
                className="block p-3 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 shadow-sm focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light"
                placeholder="Wo können wir Ihnen helfen?"
                value={formData.subject}
                onChange={handleChange}
                required
              />
            </div>
            <div className="sm:col-span-2">
              <label
                for="message"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400"
              >
                Deine Nachricht
              </label>
              <textarea
                id="message"
                rows="9"
                className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg shadow-sm border border-gray-300 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                placeholder="Schreiben Sie hier Ihre Nachricht..."
                value={formData.message}
                onChange={handleChange}
              ></textarea>
            </div>
            <button
              type="submit"
              className="py-3 px-5 text-sm font-medium text-center  text-white rounded-lg bg-primary-700 sm:w-fit hover:bg-blue
                        -800 focus:ring-4 focus:outline-none focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
            >
              Weiter
            </button>
          </form>
        </div>
{/*         <div className="w-full sm:w-4/5 lg:w-3/4 mx-auto px-4 pt-10 grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-4 lg:gab-8 justify-center text-sm lg:text-lg">
          <p className="block py-3 px-5 text-gray-70">Coming soon</p>
        </div> */}
      </div>
    </section>
  );
}
